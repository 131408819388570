import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
// import styled from "@emotion/styled";
import { ModalUnstyled } from "@mui/base";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";

import fullUrlService from "../../../services/fullUrlService";
import PaymentMethods from "./PaymentMethods";
import MobilePayment from "./MobilePayment";
import CardPayment from "./CardPayment";
import PaypalPayment from "./PaypalPayment";
import { firestore } from "../../../config/firebase";
import { openLinkInNewTab } from "../../../util/newTab";
import Toast from "../../../components/MessageToast";

const StyledModal = styled(ModalUnstyled)`
	position: fixed;
	z-index: 1300;
	right: 0;
	bottom: 0;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	padding: 0 5px;
`;

const Backdrop = styled("div")`
	z-index: -1;
	position: fixed;
	right: 0;
	bottom: 0;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	-webkit-tap-highlight-color: transparent;
`;

const dialogContentStyle = {
	width: "50%",
	padding: "5vh 5%",
	height: "fit-content",
	backgroundColor: "#fff",
	borderRadius: "6px",
	outline: "none",
	overflowY: "auto",
	position: "relative",
	maxHeight: "89vh",
	"@media (max-width: 600px)": {
		width: "98%",
		height: "fit-content",
	},
};

export default ({
	title = "Confirm Payment",
	description,
	paymentOptions,
	open = false,
	onCloseModel,
	type,
	itemId,
	payload,
	quantityLabel,
	completeMessage = "Your payment was successful, votes will be applied soon",
}) => {
	const [state, setState] = useState({
		method: null, // mobile, card (flutterwave), paypal (card or account)
	});

	const activeProviders = {
		momo: {
			label: "Intouch",
			name: "intouch",
			path: "/intouch",
		},
		card: {
			label: "DPO Direct Pay",
			name: "dpo",
			path: "/dpo",
		},
		paypal: {
			label: "Paypal Pay",
			name: "paypal",
			path: "/paypal",
		},
	};

	// const handleFlutterPayment = useFlutterwave({
	//   public_key: process.env.FLUTTER_PUBLIC_KEY,
	//   tx_ref: state.tx_ref,
	//   amount: state.amount,
	//   currency: state.currency,
	//   payment_options: "card",
	//   customer: {
	//     email: state.email,
	//     phonenumber: "",
	//     name: title,
	//   },
	//   customizations: {
	//     title,
	//     description,
	//     logo: "https://demand.noneho.com/assets/img/logo.png",
	//   },
	// });

	const afterInitTransaction = (response) => {
		switch (state.method) {
			case "mobile":
				setState((prev) => ({
					...prev,
					showOTP:
						activeProviders.momo.name === "flutterwave" &&
						!response.error &&
						response.results &&
						response.results.transaction.tx_ref,
					redirect:
						activeProviders.momo.name === "flutterwave" &&
						response.results &&
						response.results.charge.redirect,
					completed: activeProviders.momo.name === "intouch",
				}));
				break;
			case "card":
				setState((prev) => ({
					...prev,
					initFlutterwave:
						!response.error && activeProviders.card.name === "flutterwave",
					initDPOPay: !response.error && activeProviders.card.name === "dpo",
					completed: activeProviders.card.name === "dpo",
				}));
				if (activeProviders.card.name === "dpo" && !response.error) {
					// redirect to DPO payment page
					window.location.assign(
						`https://secure.3gdirectpay.com/payv3.php?ID=${response.results.charge.transToken}`
					);
				}
				break;
			case "paypal":
				setState((prev) => ({
					...prev,
					showPaypal:
						!response.error && activeProviders.paypal.name === "paypal",
					completed: false,
				}));
				break;
			default:
				break;
		}
	};

	const initializePayment = async ({
		email,
		phoneNumber,
		amount,
		currency,
	}) => {
		setState({
			...state,
			loading: true,
			error: false,
			completed: false,
			showPaypal: false,
			showOTP: false,
		});
		const quantity =
			state.method === "mobile"
				? paymentOptions.mobileOptions.find((el) => el.amount === amount)
						.quantity
				: paymentOptions.cardOptions.options[currency].find(
						(el) => el.amount === amount
				  ).quantity;

		const data = {
			amount,
			email,
			phone_number: phoneNumber,
			currency,
			type,
			id: itemId,
			method: state.method,
			customer: {
				email,
				phoneNumber,
			},
			payload: {
				...payload,
				[quantityLabel]: quantity,
			},
			description,
			originalUrl: `https://${
				window.location.hostname
			}/payment/complete?returnUrl=${
				window.location.pathname
			}&message=${completeMessage.replace("votes", quantity + " votes")}`,
		};

		let transAPIPath = process.env.PAYMENT_API;
		if (state.method === "card") transAPIPath += activeProviders.card.path;
		else if (state.method === "paypal") {
			transAPIPath += activeProviders.paypal.path;
		} else transAPIPath += activeProviders.momo.path;

		const response = await fullUrlService({
			method: "POST",
			path: transAPIPath,
			data,
		});
		setState({
			...state,
			message: response.message,
			loading: false,
			error: response.error,
			tx_ref: response.results && response.results.transaction.tx_ref,
			amount,
			currency,
			email,
		});
		afterInitTransaction(response);
	};

	const onOTPSubmit = async (otp) => {
		const data = {
			otp,
			redirect: state.redirect,
		};
		setState({ ...state, loading: true });
		const response = await fullUrlService({
			method: "POST",
			path: `${process.env.PAYMENT_API}/flutterwave/${state.tx_ref}/otp`,
			data,
		});
		setState({
			...state,
			message: response.message,
			loading: false,
			error: response.error,
			completed: !response.error,
			showOTP: response.error,
		});
	};

	const onApprovePaypalOrder = () => {
		setState({
			...state,
			message: "Your payment is being processed, we will notify you once done",
			loading: false,
			error: false,
			completed: true,
			showPaypal: false,
		});
	};

	const onCancelPaypalOrder = () => {
		setState({
			...state,
			message: "Payment was cancelled, please try again",
			loading: false,
			error: true,
			completed: true,
			showPaypal: false,
		});
	};

	const onErrorPaypalOrder = () => {
		setState({
			...state,
			message: "An error occured processing payment, please try again",
			loading: false,
			error: true,
			completed: true,
			showPaypal: false,
		});
	};

	const onClosingModal = () => {
		setState({
			method: null,
		});
		onCloseModel();
	};

	const onChoosePaymentMethod = (method) => {
		setState({ ...state, method });
	};

	useEffect(() => {
		let payQueryListener;
		if (state.completed && !state.error) {
			const payQuery = firestore
				.collection(process.env.COMPILED_TRANSACTIONS_COLLECTION)
				.doc(state.tx_ref);
			payQueryListener = payQuery.onSnapshot((snap) => {
				if (snap.exists) {
					const { status } = snap.data();
					if (status === "successful") {
						setState({
							method: null,
						});
						onClosingModal();
						Toast({
							message: completeMessage,
							type: "success",
							duration: 4000,
						});
					}
					if (status.includes("fail")) {
						setState({
							method: null,
						});
						onClosingModal();
						Toast({
							message:
								"Your payment was not successfuly processed, try again or contact support",
							type: "error",
							duration: 4000,
						});
					}
				}
			});
		}
		return payQueryListener;
	}, [state.completed]);

	if (state.initFlutterwave) {
		// handleFlutterPayment({
		//   callback: (res) => {
		//     closePaymentModal();
		//     setState({
		//       ...state,
		//       completed: true,
		//       message: "Payment didn't complete, please try again!",
		//       loading: false,
		//       error: res.status === "failed",
		//       initFlutterwave: false,
		//     });
		//   },
		//   onClose: () => {
		//     closePaymentModal();
		//   },
		// });
	}

	return (
		<StyledModal
			aria-labelledby="Noneho Payment Dialog"
			aria-describedby="Perform payment for Noneho services online"
			open={open}
			onClose={onClosingModal}
			BackdropComponent={Backdrop}
		>
			<Box sx={dialogContentStyle}>
				<IconButton
					aria-label="Close"
					onClick={onClosingModal}
					style={{
						float: "right",
						margin: "-3vh -3%",
					}}
				>
					<CloseIcon />
				</IconButton>
				<Typography variant="h6">{title}</Typography>
				<br></br>
				{state.method === "mobile" ? (
					<MobilePayment
						onPaymentSubmit={initializePayment}
						onOTPSubmit={onOTPSubmit}
						message={state.message}
						showOTP={state.showOTP}
						paymentOptions={paymentOptions.mobileOptions}
						loading={state.loading}
						error={state.error}
						completed={state.completed}
					/>
				) : state.method === "card" ? (
					<CardPayment
						onPaymentSubmit={initializePayment}
						message={state.message}
						loading={state.loading}
						error={state.error}
						completed={state.completed}
						paymentOptions={paymentOptions.cardOptions}
					/>
				) : state.method === "paypal" ? (
					<PaypalPayment
						onPaymentSubmit={initializePayment}
						message={state.message}
						loading={state.loading}
						error={state.error}
						completed={state.completed}
						paymentOptions={paymentOptions.cardOptions}
						description={description}
						tx_ref={state.tx_ref}
						showPaypal={state.showPaypal}
						onApprovePaypalOrder={onApprovePaypalOrder}
						onCancelPaypalOrder={onCancelPaypalOrder}
						onErrorPaypalOrder={onErrorPaypalOrder}
					/>
				) : (
					<PaymentMethods onChoosePaymentMethod={onChoosePaymentMethod} />
				)}
				<Paper
					elevation={0}
					sx={{
						textAlign: "end",
						position: "absolute",
						right: "0px",
						bottom: "15px",
						paddingRight: "30px",
					}}
				>
					<Link href="/terms-conditions" target="_blank" sx={{ color: "gray" }}>
						Terms & conditions
					</Link>
				</Paper>
			</Box>
		</StyledModal>
	);
};
